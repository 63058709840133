<template>
  <div class="wrapper">
    <div class="new-tree-form ">
      <div class="custom-buttons-holder">
      <v-container fluid>
        <v-row justify="end">
          <v-col cols="12">
            <v-row class="first-row">
              <v-col cols="2" v-show="userCanDelete">
                <a href="javascript:void(0);" class="delete">
                  <span class="mdi mdi-trash-can-outline custom-icon"></span
                  ><span @click="showDeletePopup = true">Löschen</span>
                </a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
      <v-container fluid>
        <v-form @submit.prevent="submitFunction" ref="editTaskForm">
          <v-row>
            <v-col cols="6" class="form-element-holder">
              <div>
                <p class="custom-label">Etappe*</p>

                <div class="fields">
                  <v-menu
                    ref="dateMenu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="dateToShow"
                        append-icon="mdi-calendar-blank-outline"
                        outlined
                        readonly
                        hide-details
                        clearable
                        v-on="on"
                        @click:clear="
                          clearDate();
                          generateTable();
                        "
                        :rules="requiredRule"
                        @focus="checkInputs" 
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      @input="generateTable"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dateMenu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dateMenu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </div>
            </v-col>
            <v-col cols="6" class="form-element-holder">
              <div>
                <p class="custom-label">Massnahme*</p>

                <div
                  class="fields"
                  @click="showWtPopupFunction(isComingFromBaumKontroll)"
                >
                  <v-text-field
                    outlined
                    clearable
                    readonly
                    hide-details
                    :disabled="isComingFromBaumKontroll ? true : false"
                    :value="finalWorkTypeSelection"
                    @click:clear="clearedWorktypeSelection"
                    :rules="requiredRule"
                    @focus="checkInputs" 

                  >
                    <v-icon slot="append" color="white">
                      mdi-chevron-down
                    </v-icon>
                  </v-text-field>
                </div>
              </div>
            </v-col>

            <v-col cols="12" class="form-element-holder">
              <div>
                <p class="custom-label">BaumID*</p>

                <div class="fields" @click="showBaumPopupFunction">
                  <v-text-field
                    outlined
                    clearable
                    readonly
                    hide-details
                    :value="finalBaumSelection"
                    @click:clear="clearBaumSelection()"
                    @input="generateTable"
                    :rules="requiredRule"
                    @focus="checkInputs" 

                  >
                    <v-icon slot="append" color="white">
                      mdi-chevron-down
                    </v-icon>
                  </v-text-field>
                </div>
              </div>
            </v-col>

            <v-col cols="4" class="form-element-holder mb-2">
              <div>
                <p class="custom-label">Intervall</p>
                <div class="fields">
                  <v-text-field
                    class="number-input"
                    v-model="intervalValue"
                    append-outer-icon="mdi-plus"
                    prepend-icon="mdi-minus"
                    outlined
                    hide-spin-buttons
                    @click:append-outer="
                      plusClicked('intervalValue');
                      generateTable();
                    "
                    @click:prepend="
                      minusClicked('intervalValue');
                      generateTable();
                    "
                    @input="generateTable"
                    :rules="positiveNumberRule"
                  ></v-text-field>
                </div>
              </div>
            </v-col>

            <v-col cols="4" class="form-element-holder mb-2">
              <div>
                <p class="custom-label">Wiederholen</p>

                <div class="fields">
                  <v-text-field
                    class="number-input"
                    v-model="repeatlValue"
                    append-outer-icon="mdi-plus"
                    prepend-icon="mdi-minus"
                    outlined
                    hide-spin-buttons
                    @click:append-outer="
                      plusClicked('repeatlValue');
                      generateTable();
                    "
                    @click:prepend="
                      minusClicked('repeatlValue');
                      generateTable();
                    "
                    @input="generateTable"
                    :rules="positiveNumberRule"
                  ></v-text-field>
                </div>
              </div>
            </v-col>

            <v-col cols="4" class="form-element-holder">
              <div>
                <p class="custom-label">Spz.Std</p>

                <div class="fields">
                  <v-text-field
                    class="number-input"
                    v-model="specialHrsValue"
                    append-outer-icon="mdi-plus"
                    prepend-icon="mdi-minus"
                    hide-details
                    outlined
                    hide-spin-buttons
                    @click:append-outer="
                      plusClicked('specialHrsValue');
                      generateTable();
                    "
                    @click:prepend="
                      minusClicked('specialHrsValue');
                      generateTable();
                    "
                    @input="generateTable"
                  ></v-text-field>
                </div>
              </div>
            </v-col>

            <v-col cols="4" class="form-element-holder">
              <div>
                <p class="custom-label">Bp1.Std</p>

                <div class="fields">
                  <v-text-field
                    class="number-input"
                    v-model="normalHrsValue"
                    append-outer-icon="mdi-plus"
                    prepend-icon="mdi-minus"
                    hide-details
                    outlined
                    hide-spin-buttons
                    @click:append-outer="
                      plusClicked('normalHrsValue');
                      generateTable();
                    "
                    @click:prepend="
                      minusClicked('normalHrsValue');
                      generateTable();
                    "
                    @input="generateTable"
                  ></v-text-field>
                </div>
              </div>
            </v-col>

            <v-col cols="4" class="form-element-holder">
              <div>
                <p class="custom-label">Material[Fr.]</p>

                <div class="fields">
                  <v-text-field
                    v-model="materialValue"
                    hide-details
                    outlined
                    @input="generateTable"
                  ></v-text-field>
                </div>
              </div>
            </v-col>

            <v-col cols="4" class="form-element-holder material-list">
              <div>
                <div class="fields">
                   <div @click="viewPDF" class="document" style="cursor: pointer;">
                    <img src="../../../assets/images/icons/berichte-active.svg" class="mr-3"/>
                    Materialliste
                   </div>
                </div>
              </div>
            </v-col>

            <v-col cols="12" class="form-element-holder">
              <div>
                <p class="custom-label">Info</p>

                <div class="fields">
                  <v-textarea
                    hide-details
                    outlined
                    auto-grow
                    :counter="maxCharacters"
                    name="infoTextarea"
                    v-model="textareaInfo"
                    ref="infoTextarea"
                    @input="generateTable"
                    rows="2"
                  ></v-textarea>
                </div>
              </div>
            </v-col>

            <v-col
              cols="12"
              class="form-element-holder"
              v-show="tableData.length > 0"
            >
              <div class="table-holder">
                <NewTaskDatatable :headers="headers" :tableData="tableData" />
              </div>
            </v-col>

            <v-col cols="12" class="form-element-holder">
              <div class="buttons">
                <v-btn
                  type="submit"
                  size="x-large"
                  height="54px"
                  color="#1db954"
                  class="submit-btn"
                >
                  OK
                </v-btn>
                <v-btn
                  size="x-large"
                  height="54px"
                  class="cancel-btn"
                  @click="showCancelPopup = true"
                >
                  Abbrechen
                </v-btn>

                
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>

    <div class="popup" v-show="showWorktypePopup" ref="worktypePopup">
      <div class="popup-holder">
        <v-icon size="36" class="close-popup" @click="this.closeWtPopupFunction"
          >mdi mdi-close</v-icon
        >
        <p class="title">Massnahme</p>

        <v-text-field
          class="search"
          placeholder="Suchen"
          v-model="workTypeSearchText"
          @keyup="searchWorkTypes"
        >
          <v-icon slot="append" color="white">
            mdi-magnify
          </v-icon>
        </v-text-field>

        <div class="alphabet">
          <v-chip-group
            v-model="selectedWtChar"
            active-class="green"
            column
            class="my-3"
          >
            <v-chip
              :key="letter"
              size="31"
              class="rounded-0 ma-1 custom-chip"
              v-for="letter in letters"
              :ripple="false"
              :class="selectedWtChar === letter ? 'green' : ''"
              @click="selectLetter(letter)"
              outlined
            >
              <span>{{ letter }}</span>
            </v-chip>
          </v-chip-group>
        </div>

        <div class="work-types" v-show="workTypes.length">
          <v-radio-group v-model="selectedWorktype">
            <div class="radio-wrapper">
            <v-radio
              v-for="(wt, index) in workTypes"
              :key="index"
              :label="wt.abbreviation + ', ' + wt.description"
              :value="wt.abbreviation"
              class="custom-radio"
              @click="radioWtClicked(wt)"
            ></v-radio>
          </div>
          </v-radio-group>
        </div>
      </div>
    </div>

    <div class="popup" v-show="showBaumPopup" ref="baumPopup">
      <div class="popup-holder">
        <v-icon size="36" class="close-popup" @click="closeBaumPopupFunction"
          >mdi mdi-close</v-icon
        >
        <p class="title">Baum ID</p>

        <v-text-field
          :key="2"
          class="search"
          placeholder="Suchen"
          v-model="baumSearchText"
          @keyup="searchBaums"
        >
          <v-icon slot="append" color="white">
            mdi-magnify
          </v-icon>
        </v-text-field>

        <div class="alphabet">
          <v-chip-group
            v-model="selectedBaumChar"
            active-class="green"
            column
            class="my-3"
          >
            <v-chip
              :key="letter"
              size="31"
              class="rounded-0 ma-1 custom-chip"
              v-for="letter in letters"
              :ripple="false"
              :class="selectedBaumChar === letter ? 'green' : ''"
              @click="selectBaumLetter(letter)"
              outlined
            >
              <span>{{ letter }}</span>
            </v-chip>
          </v-chip-group>
        </div>

        <div class="baums" v-show="baums.length">
          <v-radio-group v-model="selectedBaum">
            <div class="radio-wrapper">
            <v-radio
              v-for="(baum, index) in baums"
              :key="index"
              :label="baum.name"
              :value="baum.name"
              class="custom-radio"
              @click="baumRadioClicked(baum)"
            ></v-radio>
            </div>
          </v-radio-group>
        </div>
      </div>
    </div>

    <div class="confirm-popup" v-show="showConfirmPopup" ref="confirmPopup">
      <div class="popup-holder">
        <v-icon size="36" class="close-popup" @click="showConfirmPopup = false"
          >mdi mdi-close</v-icon
        >

        <p class="title">Änderungen an allen Aufgaben speichern?</p>

        <div class="description-holder">
          <p class="description">
            Wenn Sie auf "Ja" klicken, werden diese Änderungen in allen anderen
            Aufgaben gespeichert.
          </p>
        </div>

        <div class="buttons">
          <v-btn
            size="x-large"
            height="54px"
            class="cancel-btn"
            @click="
              updateAll = false;
              editTask();
            "
          >
          Nur diese
          </v-btn>

          <v-btn
            size="x-large"
            height="54px"
            color="#1db954"
            class="submit-btn"
            @click="
              updateAll = true;
              editTask();
            "
          >
          Alle aktualisieren
          </v-btn>
        </div>
      </div>
    </div>

    <div class="delete-popup" v-show="showDeletePopup" ref="deletePopup">
      <div class="popup-holder">
        <v-icon size="36" class="close-popup" @click="showDeletePopup = false"
          >mdi mdi-close</v-icon
        >

        <p class="title">Eintrag loschen</p>

        <div class="description-holder">
          <v-icon color="#C82210" class="popup-icon"
            >mdi mdi-trash-can-outline</v-icon
          >

          <p class="description">
            Möchten Sie diese wirklich löschen? Diese Aktion kann
            nicht rückgängig gemacht werden.
          </p>
        </div>

        <div class="buttons">
          <v-btn
            size="x-large"
            height="54px"
            class="cancel-btn"
            @click="showDeletePopup = false"
          >
            Abbrechen
          </v-btn>

          <v-btn
            size="x-large"
            height="54px"
            color="#c82210"
            class="delete-btn"
            @click="deleteTask"
          >
            Löschen
          </v-btn>
        </div>
      </div>
    </div>

    <div class="cancel-popup" v-show="showCancelPopup" ref="cancelPopup">
      <div class="popup-holder">
        <v-icon size="36" class="close-popup" @click="neinClickHandler"
          >mdi mdi-close</v-icon
        >

        <p class="title">Abbrechen</p>

        <div class="description-holder">
          <v-icon color="#C82210" class="popup-icon"
            >mdi mdi-alert-outline</v-icon
          >

          <p class="description">
            Wollen Sie die Aktion wirklich Abbrechen?
          </p>
        </div>

        <div class="buttons">
          <v-btn
            size="x-large"
            height="54px"
            color="#c82210"
            class="delete-btn"
            @click="redirectToTasks"
          >
            Ja
          </v-btn>
          <v-btn
            size="x-large"
            height="54px"
            class="cancel-btn"
            @click="neinClickHandler"
          >
            Nein
          </v-btn>
        </div>
      </div>
    </div>

    <v-alert
      :type="hasError ? 'error' : 'success'"
      elevation="20"
      v-show="showAlert"
      class="alert"
    >
      {{
        hasError
          ? "Es ist ein Fehler aufgetreten"
          : `Erfolgreich ${isDeleteAlert ? "gelöscht" : "bearbeitet"}  Aufgabe`
      }}
    </v-alert>
    <v-snackbar v-model="snackbar"  :timeout="timeout" top color="danger">
      <div>{{ snackbarMessage }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn color="" text v-bind="attrs" @click.prevent="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <CustomLoader v-show="isLoading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";
import NewTaskDatatable from "../../../components/custom_components/newTaskDatatable.vue";
import CustomLoader from "../../../components/custom_components/CustomLoader.vue";

export default {
  /* Check if the next route is already set or if navigation is explicitly allowed */
  /* allowNavigate is when we create and want to redirect without showing popup */
  beforeRouteLeave(to, from, next) {
    if (this.nextRoute || this.allowNavigate || this.isFormChanged === false) {
      next();
    } else {
      this.nextRoute = to;
      this.showCancelPopup = true;
    }
  },
  components: {
    NewTaskDatatable,
    CustomLoader
  },
  data() {
    return {
      isFormChanged: false,
      timeout: 2000,
      snackbar: false,
      snackbarMessage:"",
      isLoading: false,
      hasError: false,
      showAlert: false,
      nextRoute: null,
      allowNavigate: false,
      isDeleteAlert: false,
      isComingFromBaumKontroll: false,
      showCancelPopup: false,
      showDeletePopup: false,
      showConfirmPopup: false,
      taskId: null,
      updateAll: false,
      year: null,

      userCanDelete: false,

      headers: [
        {
          text: "Datum",
          value: "date"
        },
        {
          text: "Baum ID",
          value: "selectedBaum"
        },
        {
          text: "Massnahme",
          value: "selectedWorktype"
        },
        {
          text: "Bp1[h]",
          value: "normalHrsValue"
        },
        {
          text: "Spez[h]",
          value: "specialHrsValue"
        },
        {
          text: "Material[Fr.]",
          value: "materialValue"
        },
        {
          text: "Info",
          value: "textareaInfo"
        }
      ],
      taskData: [],
      tableData: [],
      letters: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ],

      dateMenu: false,
      date: "",
      showWorktypePopup: false,
      selectedWtChar: null,
      previouslySelectedChar: null,
      selectedWorktype: null,
      workTypes: [],
      finalWorkTypeSelection: "",
      finalWorkTypeSelectionId: null,
      workTypeSearchText: "",

      showBaumPopup: false,
      selectedBaumChar: null,
      previouslySelectedBaumChar: null,
      selectedBaum: null,
      baums: [],
      finalBaumSelection: "",
      finalBaumSelectionId: null,
      baumSearchText: "",

      intervalValue: 1,
      repeatlValue: 1,
      specialHrsValue: 1,
      normalHrsValue: 1,
      materialValue: "",
      maxCharacters: 300,
      textareaInfo: "",

      /* rules */
      requiredRule: [v => !!v || "Dieses Feld muss eine Nummer sein!"],
      positiveNumberRule: [
        v => {
          v = String(v);
          if (v != null && v != "") {
            if (isNaN(parseInt(v))) return "Dieses Feld muss eine Nummer sein!";
            return true;
          } else {
            return "Dieses Feld muss eine Nummer sein!";
          }
        },
        v =>
          (Number.isInteger(Number(v)) && Number(v) > -1) ||
          "Bitte unr ganze zahlen verwenden"
      ],
      // textareaRule: [
      //   v => {
      //     if (!v) {
      //       return "Message is required";
      //     } else if (v.length > this.maxCharacters) {
      //       return "Message is too long";
      //     } else {
      //       return true;
      //     }
      //   }
      // ]
    };
  },
  computed: {
    ...mapGetters(["inventarId"]),

    dateToShow() {
      return this.date ? moment(this.date).format("DD-MM-YYYY") : "";
    }
  },
  mounted() {
    this.taskId = this.$route.params.taskId;

    this.year = this.$route.params.year;

    this.isComingFromBaumKontroll = this.$route.params.fromBaumKontroll;

    this.userCanDelete = this.$route.params.canDelete;

    this.fetchTaskData();
  },
  watch: {
    intervalValue(newVal) {
      if (newVal === 0) {
        this.repeatlValue = 0;
      }
    }
  },
  methods: {
    checkInputs() {
      this.isFormChanged = true;
    },
    neinClickHandler(){
      this.nextRoute = null;
      this.allowNavigate = false;
      this.showCancelPopup = false;
    },
    async fetchTaskData() {
      this.isLoading = true;
      try {
        const response = await axios.get(
          `https://tilia.rrota.org/api/Tasks/Get/${this.inventarId}/${this.taskId}/${this.year}`
        );

        this.taskData = response.data;

        this.populateFields();

        this.isLoading = false;
        this.isDeleteAlert = false;
        this.hasError = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.hasError = true;
        this.isDeleteAlert = false;
        this.showAlert = true;
        setTimeout(() => {
               this.showAlert = false; 
        }, 2000);
      }
    },
    async viewPDF() {
      
      let link = "";
      const materialListURL = await axios.get('https://tilia.rrota.org/api/Management/GetTasksMaterial').then((response) => {
            link = response
          });
    window.open(link.data, '_blank');
    },
    async fetchWorkTypes(name) {
      var endpoint = `https://tilia.rrota.org/api/WorkType/GetAll`;
      if (name) {
        endpoint += `?name=${name}`;
      }

      this.isLoading = true;
      try {
        const response = await axios.get(endpoint);
        this.workTypes = response.data;

        this.isLoading = false;
        this.isDeleteAlert = false;
        this.hasError = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
        this.isDeleteAlert = false;
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false; 
        }, 2000);
      }
    },

    async fetchBaums(name) {
      var endpoint = `https://tilia.rrota.org/api/Tree/GetAllWithParameters/${this.inventarId}`;

      if (name) {
        endpoint += `?name=${name}`;
      }

      this.isLoading = true;

      try {
        const response = await axios.get(endpoint);
        this.baums = response.data;

        this.isLoading = false;
        this.isDeleteAlert = false;
        this.hasError = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
        this.isDeleteAlert = false;
        this.showAlert = true;
        setTimeout(() => {
        this.showAlert = false; 
        }, 2000);
      }
    },

    async deleteTask() {
      this.isLoading = true;
      try {
        const response = await axios.delete(
          `https://tilia.rrota.org/api/Tasks/Delete/${this.taskId}`
        );

        if (response.status === 200) {
          this.isLoading = false;
          this.hasError = false;
          this.showDeletePopup = false;
          this.allowNavigate = true;
          this.isDeleteAlert = true;
          this.showAlert = true;
          setTimeout(() => {
          this.showAlert = false;  
          this.$router.push({
          name: "Pflegeplan"
        });
        }, 2000);
        }
      } catch (error) {
        console.log(error);
        this.hasError = true;
        this.showDeletePopup = false;
        this.isDeleteAlert = true;
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false; 
        }, 2000);
      }
    },

    populateFields() {
      this.date = moment(this.taskData.date).format("YYYY-MM-DD");
      this.finalWorkTypeSelectionId = this.taskData.workTypeId;
      this.finalWorkTypeSelection = this.taskData.workTypeAbbreviation;
      this.selectedWorktype = this.taskData.workTypeAbbreviation;

      this.intervalValue = this.taskData.interval;
      this.repeatlValue = this.taskData.repeat;
      this.normalHrsValue = this.taskData.normalHours;
      this.specialHrsValue = this.taskData.specialHours;
      this.materialValue = this.taskData.material;
      this.textareaInfo = this.taskData.info;

      this.finalBaumSelectionId = this.taskData.treeId;
      this.finalBaumSelection = this.taskData.treeName;
      this.selectedBaum = this.taskData.treeName;

      this.generateTable();
    },

    async editTask() {

      this.isLoading = true;
      try {
        const response = await axios.put(
          "https://tilia.rrota.org/api/Tasks/Update",
          {
            id: this.taskId,
            date: this.date,
            workTypeId: this.finalWorkTypeSelectionId,
            interval: this.intervalValue,
            repeat: this.repeatlValue,
            normalHours: this.normalHrsValue,
            specialHours: this.specialHrsValue,
            material: this.materialValue,
            info: this.textareaInfo,
            updateAll: this.updateAll,
            tasksDetails: {
              treeId: this.finalBaumSelectionId
            }
          }
        );

        if (response.status === 200) {

          this.allowNavigate = true;
          this.isLoading = false;
          this.hasError = false;
          this.showConfirmPopup = false;
          this.isDeleteAlert = false;
          this.showAlert = true;
          setTimeout(() => {
            if (this.nextRoute) {
              this.$router.push(this.nextRoute);
            } else {
              this.allowNavigate = true;
              /* if worktype id is baumkontroll, redirect to baumkontroll tasks */
              if ((this.finalWorkTypeSelectionId === 1020)) {
                this.$router.push({
                  name: "Baumkontrolle"
                });
              } else {
                this.$router.push({
                  name: "Pflegeplan"
                });
              }
            }
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.hasError = true;
        this.showConfirmPopup = false;
        this.isDeleteAlert = false;
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false; 
        }, 2000);
      }
    },

    clearDate() {
      this.isFormChanged = true;
      this.date = "";
    },

    selectLetter(letter) {
      this.selectedWtChar = letter;

      if (letter === this.previouslySelectedChar) {
        this.selectedWtChar = null;
      }
      this.previouslySelectedChar = this.selectedWtChar;

      if (this.selectedWtChar) {
        this.fetchWorkTypes(this.selectedWtChar);
      } else {
        this.fetchWorkTypes();
      }
      this.workTypeSearchText = "";
    },

    showWtPopupFunction(isComingFromBC) {
      if (isComingFromBC === true) {
        return;
      } else {
        this.showWorktypePopup = true;
        this.fetchWorkTypes();
      }
    },

    clearedWorktypeSelection() {
      this.isFormChanged = true;
      this.finalWorkTypeSelection = "";
      this.finalWorkTypeSelectionId = null;
      this.selectedWorktype = null;
      this.generateTable();
    },

    closeWtPopupFunction() {
      this.showWorktypePopup = false;
      this.workTypeSearchText = "";
      this.workTypes = [];
    },

    searchWorkTypes() {
      if (this.workTypeSearchText) {
        clearTimeout(this.searchTimeOut);
        this.searchTimeOut = setTimeout(() => {
          this.fetchWorkTypes(this.workTypeSearchText);
          clearTimeout(this.searchTimeOut);
        }, 300);
      } else {
        this.workTypes = [];
        this.fetchWorkTypes();
      }
      this.selectedWtChar = false;
      this.previouslySelectedChar = null;
    },

    radioWtClicked(wt) {
      this.finalWorkTypeSelection = wt.abbreviation;
      this.finalWorkTypeSelectionId = wt.id;
      this.showWorktypePopup = false;
      this.generateTable();
    },

    /* functions for baum */
    showBaumPopupFunction() {
      this.showBaumPopup = true;
      this.fetchBaums();
    },

    closeBaumPopupFunction() {
      this.showBaumPopup = false;
      this.baumSearchText = "";
      this.baums = [];
    },

    clearBaumSelection() {
      this.isFormChanged = true;
      this.finalBaumSelection = "";
      this.finalBaumSelectionId = null;
      this.selectedBaum = null;
      this.generateTable();
    },

    searchBaums() {
      if (this.baumSearchText) {
        clearTimeout(this.searchTimeOut);
        this.searchTimeOut = setTimeout(() => {
          this.fetchBaums(this.baumSearchText);
          clearTimeout(this.searchTimeOut);
        }, 300);
      } else {
        this.baums = [];
        this.fetchBaums();
      }
      this.selectedBaumChar = false;
      this.previouslySelectedBaumChar = null;
    },

    selectBaumLetter(letter) {
      this.selectedBaumChar = letter;

      if (letter === this.previouslySelectedBaumChar) {
        this.selectedBaumChar = null;
      }
      this.previouslySelectedBaumChar = this.selectedBaumChar;

      if (this.selectedBaumChar) {
        this.fetchBaums(this.selectedBaumChar);
      } else {
        this.fetchBaums();
      }
      this.baumSearchText = "";
    },

    baumRadioClicked(baum) {
      this.finalBaumSelection = baum.name;
      this.finalBaumSelectionId = baum.id;
      this.showBaumPopup = false;
      this.generateTable();
    },

    generateTable() {
      if (this.date && this.intervalValue && this.repeatlValue) {
        let myArray = [];

        if (this.intervalValue === 0) {
          this.tableData = [];
          return;
        } else {
          let calculatedDate;
          let formatedCalculatedDate;

          for (let i = 0; i < this.repeatlValue; i++) {
            if (i == 0) {
              calculatedDate = moment(this.date);
              formatedCalculatedDate = calculatedDate.format("DD-MM-YYYY");
            } else {
              calculatedDate = moment(calculatedDate).add(
                this.intervalValue,
                "years"
              );
              formatedCalculatedDate = calculatedDate.format("DD-MM-YYYY");
            }

            myArray.push({
              date: formatedCalculatedDate,
              selectedBaum: this.selectedBaum,
              selectedWorktype: this.selectedWorktype,
              normalHrsValue: this.normalHrsValue,
              specialHrsValue: this.specialHrsValue,
              materialValue: this.materialValue,
              textareaInfo: this.textareaInfo
            });
          }
          this.tableData = myArray;
        }
      } else {
        this.tableData = [];
      }
    },

    minusClicked(propertyName) {
      this.isFormChanged = true;
      this[propertyName]--;
      if (isNaN(parseInt(this[propertyName])) || this[propertyName] < 0)
        this[propertyName] = 0;
    },

    plusClicked(propertyName) {
      this.isFormChanged = true;
      this[propertyName]++;
      if (isNaN(parseInt(this[propertyName]))) this[propertyName] = 0;
    },

    redirectToTasks() {
      if (this.nextRoute) {
        this.$router.push(this.nextRoute);
      } else {
        this.allowNavigate = true;
        /* if worktype id is baumkontroll, redirect to baumkontroll tasks */
        if ((this.finalWorkTypeSelectionId === 1020)) {
          this.$router.push({
            name: "Baumkontrolle"
          });
        } else {
          this.$router.push({
            name: "Pflegeplan"
          });
        }
      }
    },

    submitFunction() {
      this.$refs.editTaskForm.validate();

      if (this.$refs.editTaskForm.validate() === true) {
        this.showConfirmPopup = true;
      } else {
        this.snackbar = true;
        this.snackbarMessage = "Bitte füllen Sie alle erforderlichen Felder  aus!";
        return;
      }
    }
  }
};
</script>

<style lang="scss">
.v-snack--top{
  top:10% !important;
}
.alert {
  position: fixed !important;
  width: 100% !important; 
  max-width: 784px !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  padding: 40px 100px !important;
}

.custom-buttons-holder {
  .first-row {
    .delete {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}

.new-tree-form {
  width: 940px;
  max-width: 100%;
  margin: 0 auto;

  .form-element-holder {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 30px;

    &.material-list {
      justify-content: center;
      margin-bottom: 20px;
    }

    .document {
      display: flex;
      align-items: center;
      height: 56px;
      font-size: 16px;
      line-height: 24px;
      text-decoration: underline;
      color: #fff;

      span {
        color: #1db954;
        margin-right: 10px;
        font-size: 20px;
      }
    }

    .fields {
      .number-input {
        input[type="text"] {
          text-align: center !important;
        }

        fieldset {
          border-left: 0;
          border-right: 0;
          border-radius: 0;
          border-width: 1px;
        }
      }

      .v-input__append-outer,
      .v-input__prepend-outer {
        margin: 0;
        // background: #1db954;
        // padding: 16px;
        cursor: pointer;
        border-radius: 4px;

        .v-input__icon {
          width: auto;
          height: auto;
        }

        .v-icon {
          color: #000000 !important;
          background-color: #1db954;
          padding: 16px;

          &::after {
            opacity: 0 !important;
          }
        }
      }

      .v-input__append-outer {
        .v-icon {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }

      .v-input__prepend-outer {
        .v-icon {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;

      .submit-btn {
        width: 285px;
        color: #000;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        text-transform: none;
        background-color: #1db954;
      }

      .cancel-btn {
        width: 285px;
        color: #fff;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        text-transform: none;
        background-color: transparent;
        border: 1px solid #fff;
      }
    }
  }
}

.popup,
.confirm-popup,
.delete-popup,
.cancel-popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.3);
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;

  .popup-holder {
    display: flex;
    flex-direction: column;
    background-color: #1c1e1c;
    width: 662px;
    height: auto;
    max-height: 78vh;
    max-height: 80%;
    border-radius: 10px;
    padding: 30px;
    position: relative;

    .close-popup {
      position: absolute;
      right: 30px;
      top: 25px;
      cursor: pointer;
    }

    .title {
      text-align: center;
    }

    .description-holder {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 200px;

      .popup-icon {
        margin-bottom: 20px;
        font-size: 50px;
      }

      .description {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 0;
        text-align: center;
      }
    }

    .work-types,
    .baums {
      height: 100%;
      max-height: 304px;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 3px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #1db954;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: darken($color: #1db954, $amount: 5);
      }

      .custom-radio {
        padding: 20px 0;
        border-bottom: 1px solid #494949;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;

      .submit-btn {
        width: 285px;
        color: #000;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        text-transform: none;
        background-color: #1db954;
      }

      .delete-btn {
        width: 285px;
        color: #fff;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        text-transform: none;
        background-color: #c82210;
      }

      .cancel-btn {
        width: 285px;
        color: #fff;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        text-transform: none;
        background-color: transparent;
        border: 1px solid #fff;
      }
    }
  }
}
</style>
