<template>
  <div>
    <v-container class="container">
      <v-row class="first-row" justify="center">
        <v-col cols="3">
          <label for="TreeType:">Baumart*</label>
          <v-autocomplete
            v-model="selectedTreeTypeId"
            :items="treeType"
            item-value="id"
            :item-text="(item) => `${item.abbreviation} - ${item.latinName}- ${item.germanName}`"
            :filter="customFilter"
            outlined
            clearable
            hide-details
            required
            label="Suche"
            @change="selectTreeTypeId(selectedTreeTypeId)"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="3">  
          <label for="mushroomType:">Holzpilz</label>
          <v-autocomplete
            v-model="selectedMushroomTypeId"
            :items="mushroomType"
            item-value="id"
            :item-text="(item) => `${item.abbreviation} - ${item.germanName}`"
            :filter="customFilter"
            outlined
            clearable
            hide-details
            required
            label="Suche"
            @change="selectMushroomTypeId(selectedMushroomTypeId)"
          >
          </v-autocomplete>
        </v-col>
        <v-col class="first-calendar-text-field" cols="3">
          <v-menu
            v-model="search.menuStartDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="calendar-first"
                v-model="searchData.startDateFormatted"
                append-icon="mdi-calendar"
                readonly
                :placeholder="startDatePlaceholder"
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchData.startDate"
              @input= "onStartDateSelected"
              locale="de"
              no-title
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      
      <v-row class="second-row" justify="center">
        <v-col class="column" cols="3">
          <label for="WorkType:">Massnahme</label>
          <v-autocomplete
            v-model="selectedWorkTypeId"
            :items="workType"
            item-value="id"
            :item-text="(item) => `${item.abbreviation} - ${item.description}`"
            :filter="customFilter"
            outlined
            clearable
            hide-details
            required
            label="Suche"
            @change="selectWorkTypeId(selectedWorkTypeId)"
          >
          </v-autocomplete>
        </v-col>
        <v-col class="column" cols="3">
          <label for="Parasite:">Parasit</label>
          <v-autocomplete
            v-model="selectedParasitTypeId"
            :items="parasitType"
            item-value="id"
            :item-text="(item) => `${item.name} - ${item.germanName}`"
            outlined
            clearable
            hide-details
            required
            label="Suche"
            @change="selectParasitTypeId(selectedParasitTypeId)"
          >
          </v-autocomplete>
        </v-col>
        <v-col class="second-calendar-text-field" cols="3">
          <v-menu
            v-model="search.menuEndDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="calendar-second"
                v-model="searchData.endDateFormatted"
                append-icon="mdi-calendar"
                readonly
                :placeholder="endDatePlaceholder"
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchData.endDate"
              @input= "onEndDateSelected"
              locale="de"
              no-title
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="buttons" justify="center">
             <v-col cols="3" class="search-button-container">
          <v-btn
            color="primary"
            class="search-button"
            @click="search"
            text-color:black
            >Suchen</v-btn
          >
        </v-col>
        <v-col cols="auto">
          <v-btn color="gray" class="cancel-button" @click="cancel"
            >Abbrechen</v-btn
          >
        </v-col>
   
      </v-row>
      <div v-if="showDataTable">
        <p class="ergebnis-text">Ergebnis:</p>
        <div class="table-css" v-if="showDataTable">
          <v-data-table
            :headers="tableHeaders"
            :items="tableData"
            :search="searchQuery"
            :footer-props="{
            'items-per-page-text':'Zeilen pro Seite'
          }"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon small class="green--text icon-style-one" @click="handleView(item.items)"
                >mdi-eye-outline</v-icon
              >
            </template>
            <template v-slot:item.count="{ item }">
          <div class="count-cell">
            <span class="green-number">{{ item.count }}</span>
          </div>
        </template>

          </v-data-table>
        </div>
        <div v-if="tableDataInventory.length > 0 " class="mb-5">
             
        <p class="ergebnis-text">Suchergebnisse</p>
          <v-data-table
             ref="secondTable"
            :headers="tableDataInventoryHeaders"
            :items="formattedTableDataInventory"
            :footer-props="{
            'items-per-page-text':'Zeilen pro Seite'
          }"
          >
      <template v-slot:item.actionsForTasks="{ item }">
      <v-icon small class="green--text icon-style" @click="handleViewForTask(item)">
        mdi-eye-outline
      </v-icon>
    </template>

            <template v-slot:item.count="{ item }">
          <div class="count-cell">
            <span class="green-number">{{ item.count }}</span>
          </div>
        </template>

          </v-data-table>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import { format } from 'date-fns';
import { de as deLocale} from "date-fns/locale"
import {createLogger, mapActions} from "vuex";


export default {
  name: "Search",
  data() {
    return {
      filteredDataTable: [],
      showDataTable: false,
      tableHeaders: [
        { text:"Anzahl", value:"count"},
        { text: "Inventar", value: "inventoryName" },
        { text: "Anz.ba", value: "totalTrees" },
        { text: "", value: "actions", sortable: "false" },

      ],
      //second table
      tableDataInventoryHeaders: [
        { text: "Inventar", value: "inventoryName" },

        { text: "Datum", value: "formattedDate" },
        { text: "BaumId", value:"treeName"},
        { text:"Massnahme",value:"workType"},
        { text: "Parasit", value:"parasit"},
        { text: "Holzpilz",value:"mushroomType"},
        { text:"", value:"actionsForTasks",sortable:"false"}
        
      
      ],
      tableData: [],
      tableDataInventory:[],
      startDatePlaceholder:format(new Date(), 'dd-MM-yyyy'),
      endDatePlaceholder:format(new Date(), 'dd-MM-yyyy'),
      
      searchData: {
        treeTypeId: null,
        mushroomTypeId: null,
        workTypeId: null,
        parasitTypeId: null,
        menuStartDate: false,
        menuEndDate: false,
        startDate: null,
        startDateFormatted:'',
        endDateFormatted:'',
        endDate: null,
      },
      treeType: [],
      selectedTreeTypeId: null,
      mushroomType: [],
      selectedMushroomTypeId: null,
      workType: [],
      selectedWorkTypeId: null,
      parasitType: [],
      selectedParasitTypeId: null,
      searchQuery: "",
    };
  },
computed: {
  formattedTableDataInventory() {
    return this.tableDataInventory.map(item => {
      const date = new Date(item.date);
      item.formattedDate = format(date, 'dd-MM-yyyy', { locale: deLocale });
      return item;
    });
  },
},

  methods: {
    ...mapActions([
      "updateInventarId",
    ]),
      customFilter(item, queryText, itemText) {
            const abbrev = item.abbreviation.toLowerCase();
            const query = queryText.toLowerCase();
            return abbrev.startsWith(query);
      },
      handleViewForTask(item) {
      const date = new Date(item.date);
      const year = date.getFullYear();
      const inventarID = item.inventoryId
      this.updateInventarId(inventarID);
      
      if (item.workType === "Baumkontrolle") {
        this.$router.push("/app/pages/BaumkontrolleList");
      } else {
        const taskId=item.id;

        this.$router.push({
          name:"Pflegeplan Eintrag",
          params:{year,taskId}})
      }
    },
    //method to change the format of the date
  onStartDateSelected() {
    if (this.searchData.startDate) {
      this.searchData.startDateFormatted = format(
        new Date(this.searchData.startDate),
        'dd-MM-yyyy',
        { locale: deLocale }
      );
    }
  },
    //method to change the format of the date
    onEndDateSelected() {
    if (this.searchData.endDate) {
      this.searchData.endDateFormatted = format(
        new Date(this.searchData.endDate),
        'dd-MM-yyyy',
        { locale: deLocale }
      );
    }
  },
  // ... other methods ...


    cancel() {
      this.showDataTable = false;
    },
    selectTreeTypeId(treeTypeId) {
      this.searchData.treeTypeId = treeTypeId;
    },
    selectMushroomTypeId(mushroomTypeId) {
      this.searchData.mushroomTypeId = mushroomTypeId;
    },
    selectWorkTypeId(workTypeId) {
      this.searchData.workTypeId = workTypeId;
    },
    selectParasitTypeId(parasitTypeId) {
      this.searchData.parasitTypeId = parasitTypeId;
    },
    //Method to get all tree types for the dropdown
    getAllTreeType() {
      axios
        .get(`https://tilia.rrota.org/api/TreeType/GetAll`)
        .then((response) => {
          this.treeType = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //Method to get all mushroom types for the dropdown
    getAllMushroomType() {
      axios
        .get(`https://tilia.rrota.org/api/MushroomType/GetAll`)
        .then((response) => {
          this.mushroomType = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //Method to get all work types for the dropdown
    getAllWorkType() {
      axios
        .get(`https://tilia.rrota.org/api/WorkType/GetAll`)
        .then((response) => {
          this.workType = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //Method to get all parasit types for the dropdown
    getAllParasitType() {
      axios
        .get("https://tilia.rrota.org/api/Parasit/GetAll")
        .then((response) => {
          this.parasitType = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
async handleView(items) {
  const inventoryId = items[0].inventoryId;

  const searchData = {
    FromDate: this.searchData.startDate,
    ToDate: this.searchData.endDate,
    TreeTypeId: this.searchData.treeTypeId,
    MushroomTypeId: this.searchData.mushroomTypeId,
    WorkTypeId: this.searchData.workTypeId,
    ParasitId: this.searchData.parasitTypeId,
  };

  if (this.tableDataInventory.length > 0) {
    this.tableDataInventory = [];
  }

  const response = await axios.get("https://tilia.rrota.org/api/Search/GetAll", {
    params: searchData,
  });

  const filteredInventoryTasks = response.data.filter(r => r.inventoryId == inventoryId);

  filteredInventoryTasks.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    const today = new Date();
    
    const diffA = Math.abs(dateA - today);
    const diffB = Math.abs(dateB - today);
    
    return diffA - diffB;
  });

  this.tableDataInventory = filteredInventoryTasks;

  // Use setTimeout to scroll to the second table after a short delay (e.g., 100 milliseconds)
  setTimeout(() => {
    const secondTable = this.$refs.secondTable;
    if (secondTable) {
      secondTable.$el.scrollIntoView({ behavior: "smooth" });
    }
  }, 100);
},



search() {
  this.tableDataInventory = []
  if (
    !this.searchData.treeTypeId &&
    !this.searchData.mushroomTypeId &&
    !this.searchData.workTypeId &&
    !this.searchData.parasitTypeId &&
    !this.searchData.startDate &&
    !this.searchData.endDate
  )
    return;

  const searchData = {
    FromDate: this.searchData.startDate,
    ToDate: this.searchData.endDate,
    TreeTypeId: this.searchData.treeTypeId,
    MushroomTypeId: this.searchData.mushroomTypeId,
    WorkTypeId: this.searchData.workTypeId,
    ParasitId: this.searchData.parasitTypeId,
  };

  // Request to endpoint
  axios
    .get("https://tilia.rrota.org/api/Search/GetAll", {
      params: searchData,
    })
    .then((response) => {

      // Group and count by inventoryName
      const groupedData = response.data.reduce((acc, item) => {
        const { inventoryName } = item;
        if (!acc[inventoryName]) {
          acc[inventoryName] = {
            count: 0,
            totalTrees: 0,
            items: [],
          };
        }
        acc[inventoryName].count++;
        acc[inventoryName].totalTrees = item.totalTrees;
        acc[inventoryName].items.push(item);
        return acc;
      }, {});

      // Create a new array with grouped and counted data
      const tableData = Object.keys(groupedData).map((inventoryName) => ({
        inventoryName,
        count: groupedData[inventoryName].count,
        items: groupedData[inventoryName].items,
        totalTrees: groupedData[inventoryName].totalTrees,
      }));

      this.tableData = tableData;

      this.showDataTable = true;
    })
    .catch((error) => {
      console.error("Search error", error);
    });
},
    isDateInRange(date, startDate, endDate) {
      const targetDate = new Date(date);
      const start = new Date(startDate);
      const end = new Date(endDate);

      return targetDate >= start && targetDate <= end;
    },
  },
  created() {
    this.getAllTreeType();
    this.getAllMushroomType();
    this.getAllWorkType();
    this.getAllParasitType();
  },
};
</script>


<style scoped>
.first-row {
  margin-top: 6% !important;
  margin-bottom: 2% !important;
}

.container {
  margin-right: 7%;
}
.buttons {
  margin-top: 5% !important;
  margin-right: 3% !important;
}
  .search-button-container {
    margin-right: 100px; /* Adjust the margin as needed */
  }
.search-button {
  width: 285px !important;
}
.cancel-button {
  width: 285px !important;
}
.ergebnis-text {
  margin-top: 1% !important;
}

.calendar-first {
  margin-top: 22px;
}
.calendar-second {
  margin-top: 22px;
}
.icon-style {
  border: 1px solid #494949;
  padding: 10px;
  border-radius: 5px;
  width: 40px;
  height: 42px;
  transform: translateY(5%);
  margin-left:-20px;
}
.icon-style-one {
  border: 1px solid #494949;
  padding: 10px;
  border-radius: 5px;
  width: 40px;
  height: 42px;
  transform: translateX(46%);
}
.green-number { /* White background */
  color: #1DB954;/* Green text color */
  border: 1px solid #494949; /* White border */
  text-align: center;
  border-radius: 5px; /* Small border-radius */
  padding: 3px;
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.count-number {
  font-weight: bold;
}
@media (max-width: 1024px) {
  .first-row,
  .second-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .container {
    margin-right: 0;
  }
  .buttons {
    margin-top: 5% !important;
    margin-right: 1% !important;
  }

  .search-button {
    width: 285px !important;
  }

  .cancel-button {
    width: 285px !important;
  }
}
</style>