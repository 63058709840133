<template>
  <v-container class="name-number" fluid align="center">
    <button class="delete-user-modal" @click="openDeleteModal()">Löschen
      <v-icon left class="icon-style-delete">mdi mdi-trash-can-outline</v-icon>
    </button>
    <v-row justify="center">
      <v-col cols="3">
        <label for="name:">Vorname*</label>
        <v-text-field v-model="user.firstName" outlined :rules="nameRules" />
      </v-col>
      <v-col cols="3">
        <label for="initials:">Name*</label>
        <v-text-field v-model="user.lastName" outlined :rules="lastNameRules" />
      </v-col>
      <v-col cols="3">
        <label for="initials:">Initialen*</label>
        <v-text-field v-model="user.initials" outlined :rules="initialsRules" />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="3">
        <label for="phoneNumber:">Rufnummer*</label>
        <v-text-field v-model="user.phoneNumber" outlined :rules="phoneRules" />
      </v-col>
      <v-col cols="3">
        <label for="email:">Email*</label>
        <v-text-field v-model="user.email" type="email" outlined :rules="emailRules" />
      </v-col>
      <v-col cols="3">
        <label for="confirmEmail:">Email bestätigen**</label>
        <v-text-field v-model="user.confirmEmail" type="email" outlined :rules="confirmEmailRules" />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="3">
        <label for="userName:">Benutzer name*</label>
        <v-text-field v-model="user.userName" outlined :rules="userNameRules" />
      </v-col>
      <v-col cols="3">
        <label for="password:">Passwort*</label>
        <v-text-field v-model="user.password" type="password" outlined />
      </v-col>
      <v-col cols="3">
        <label for="confirmPassword:">Passwort bestätigen*</label>
        <v-text-field v-model="user.confirmPassword" type="password" outlined :rules="confirmPasswordRules" />
      </v-col>
    </v-row>
    <div class="roles" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
      Rollen:
      <!-- <span>i</span> -->
      <span class="tooltip-icon tooltip" v-if="showTooltip">{{
        tooltipText
      }}</span>
      <!-- <span class="tooltip-icon">i</span>
      <div class="tooltip" v-if="showTooltip">{{ tooltipText }}</div> -->
    </div>
    <v-row justify="center" class="my-n8">
      <v-col cols="2">
        <v-checkbox v-model="user.isAdmin" label="Admin" />
      </v-col>
      <v-col cols="4">
        <v-checkbox v-model="user.isManager" label="Verwaltende*r" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="2">
        <v-checkbox v-model="user.isFieldWorker" label="Ausführende*r" />
      </v-col>
      <v-col cols="4">
        <v-checkbox v-model="user.isInventoryOwner" label="Inventarbesitzer*in" />
      </v-col>
    </v-row>
    <v-row>
      <v-row justify="center">
        <v-col cols="2"></v-col>
        <v-col cols="6">
          <label for="confirmPassword:">Beschreibung</label>
          <v-text-field v-model="user.description" outlined :rows="2" :height="100" />
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>

    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn color="primary" @click="updateUser" text-color="black">Aktualisieren</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn color="gray" @click="resetForm">Abbrechen</v-btn>
      </v-col>

    </v-row>
    <v-alert type="success" elevation="20" v-show="showAlert" :color="alertColor" class="alert">
      {{ alertText }}
    </v-alert>
    <delete-user :visible="showDeleteModal" :user="user" :item-list="itemList"
      @close="showDeleteModal = false"></delete-user>
  </v-container>
</template>
<script>
import axios from "axios";
import DeleteUser from "./DeleteUser.vue"
//getting the user type
const UserType = {
  AdminUserType: 1,
  ManagerUserType: 2,
  FieldWorkerUserType: 3,
  InventoryOwnerUserType: 4
};
export default {

  props: {
    user: {
      type: Object,
      default: () => ({
        id: null,
        userType: "",
        userName: "",
        firstName: "",
        lastName: "",
        initials: "",
        phoneNumber: "",
        email: "",
        password: "",
        description: "",
        confirmEmail: "",
        confirmPassword: "",

      })

    },
    itemList: Array

  },
  components: {
    DeleteUser

  },
  created() {
    const userId = +this.$route.params.id;
    axios
      .get("https://tilia.rrota.org/api/User/GetAll?includeOwners=true")
      .then(response => {
        const userList = response.data;
        const user = userList.find(u => u.id == userId);
        debugger
        if (user) {
          this.user.id = user.id;
          this.user.userType = user.userType || "";
          this.user.userName = user.username || "";
          this.user.firstName = user.firstName || "";
          this.user.lastName = user.lastName || "";
          this.user.initials = user.initials || "";
          this.user.phoneNumber = user.phoneNumber || "";
          this.user.email = user.email || "";
          this.user.description = user.description || "";
          this.user.confirmEmail = user.email || "";

          this.user.isAdmin = user.userTypeId == UserType.AdminUserType;
          this.user.isManager = user.userTypeId == UserType.ManagerUserType;
          this.user.isFieldWorker = user.userTypeId == UserType.FieldWorkerUserType;
          this.user.isInventoryOwner = user.userTypeId == UserType.InventoryOwnerUserType;
        } else {
          // Handle the case when user is not found, e.g., display an error message
          console.log("User not found");
        }
      })
      .catch(error => {
        console.log(error);
      });
  },



  data() {
    return {
      tooltipText:
        "Wähle die Rollen aus: Admin, Ausführende*r, Verwaltende*r, Inventarbesitzer*in",
      showTooltip: false,
      showDeleteModal: false,
      userList: {},
      isLoading: false,
      showAlert: false,
      alertText: "",
      alertColor: "",
      nameRules: [
        v => !!v || 'Vorname ist erforderlich',
        v =>  /^[a-zA-ZäöüÄÖÜß ]+$/.test(v) || 'Der Vorname darf nur Buchstaben und Leerzeichen enthalten',
      ],
      lastNameRules: [
        v => !!v || 'Name ist erforderlich',
        v =>  /^[a-zA-ZäöüÄÖÜß ]+$/.test(v) || 'Der Name darf nur Buchstaben und Leerzeichen enthalten',
      ],
      initialsRules: [
        v => !!v || 'Initialen sind erforderlich',
        v =>  /^[a-zA-ZäöüÄÖÜß ]+$/.test(v) || 'Die Initialen dürfen nur Buchstaben und Leerzeichen enthalten',
      ],
      phoneRules: [
        v => !!v || 'Telefonnummer ist erforderlich',
        (v) =>
          /^(?:(\+41|\+49)|0)([\s\d]*)$/.test(v) ||
          "Ungültige Rufnummer. Die Nummer sollte mit +41, +49 oder 0 beginnen.",
      ],
      emailRules: [
        v => !!v || 'E-mail ist erforderlich',
        v => /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v) || 'Ungültige E-mail',
      ],
      confirmEmailRules: [
        v => !!v || 'E-Mail-Bestätigung ist erforderlich',
        v => v === this.user.email || 'E-Mails stimmen nicht überein',
      ],
      userNameRules: [
        v => !!v || 'Benutzername ist erforderlich',
        v => /^[a-zA-Z0-9äöüÄÖÜ]+$/.test(v) || 'Der Benutzername darf nur Buchstaben und Zahlen enthalten',
      ],
      confirmPasswordRules: [
        v => !!v || 'Passwortbestätigung ist erforderlich',
        v => v === this.user.password || 'Passwörter stimmen nicht überein',
      ],
      descriptionRules: [
        v => !!v || 'Beschreibung ist erforderlich',
        v => v.length <= 255 || 'Die Beschreibung muss weniger als 255 Zeichen enthalten',
      ],

    };
  },


  methods: {
    openDeleteModal() {
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.$router.push({ name: "/app/pages/management/Liste der Nutzenden" })

    },

    updateUser() {
      this.user.userType = this.getUserType();
      const payload = {
        id: this.user.id,
        userTypeId: this.user.userType,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        initials: this.user.initials,
        phoneNumber: this.user.phoneNumber,
        email: this.user.email,
        username: this.user.userName,
        password: this.user.password,
        confirmPassword: this.user.confirmPassword,
        isActive: true,
        isConfirmed: true,
        description: this.user.description,
      };
      axios
        .put("https://tilia.rrota.org/api/User/UpdateUserViaAdmin", payload)
        .then(response => {
          this.isLoading = false;
          const index = this.itemList ? this.itemList.findIndex(item => item.id === response.data.id) : -1;
          if (index >= 0) {
            this.$set(this.itemList, index, response.data);
          }
          if (response.status === 200) {
            this.showAlert = true;
            this.alertColor = "success"
            this.alertText = "Der Benutzer wurde erfolgreich aktualisiert";
            setTimeout(() => {
              this.showAlert = false;
              this.$router.push({ name: "Liste der Nutzenden" });
            }, 1500);
          }
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
          this.showAlert = true;
          this.alertColor = "error";
          this.alertText = "Fehler beim speichern der Benutzer";
          setTimeout(() => {
            this.showAlert = false;
          }, 2000);
        });
    },
    getUserType() {
      let userType = "";
      if (this.user.isAdmin) {
        userType = UserType.AdminUserType;
      } else if (this.user.isManager) {
        userType = UserType.ManagerUserType;
      } else if (this.user.isFieldWorker) {
        userType = UserType.FieldWorkerUserType;
      } else if (this.user.isInventoryOwner) {
        userType = UserType.InventoryOwnerUserType;
      }
      return userType;


    },
    resetForm() {
      this.$router.push({ name: "Liste der Nutzenden" }); // navigate to userList page
    }


  },
};
</script>
<style>
.roles {
  margin: 0 auto;
  margin-bottom: 2%;
  width: 50%;
  padding: 9.2px;
  font-size: 16px;
  float: center;
  position: relative;
}


.tooltip-icon {
  font-size: 18px;
  margin-left: 18px;
  cursor: help;
}

.tooltip {
  margin: 0 auto;

  /* position: absolute; */
  text-align: center;

  top: 10%;
  left: 15%;
  display: none;
  font-size: 16px;
  background-color: rgba(146, 140, 140, 0.678);
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #ccc;
  z-index: 1;
}


.roles:hover .tooltip {
  display: block;
}


.alert {
  position: fixed;
  width: 100%;
  max-width: 784px;
  left: 56%;
  top: 45%;
  transform: translate(-50%, -50%);
  padding: 40px 100px;
}

.delete-user-modal {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.icon-style-delete {
  color: #C82210 !important;
  margin-left: 3px;
}
</style>
