<template>
  <div>
    <v-card class="custom-buttons-holder">
      <v-container fluid>
        <v-row justify="end" >
          <v-col cols="3">
            <v-select class="mt-8"
              v-model="year"
              :items="orderedYearsWithAll"
              outlined
              @change="yearChange"
              menu-props="auto"
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="3">

          <label>Farbe</label>

          <v-select class="mt-3"
          v-model="selectedColor"
          :items="colorOptions"
          item-value="value"  
          hide-details
          outlined
          @change="colorChange"

          ></v-select> 
        </v-col>

          <v-col cols="6">
            <v-row class="first-row">
              <v-col cols="2" v-if="!hideFilters">
                <a class="carte" @click="viewSelectedItemOnMap" >
                  <img
                    class="karte mr-2"
                    src="../../../assets/images/map.png"
                    alt=""
                  /><span>Karte</span>
                  <div class="information" v-if="showInfo">
                    Sie müssen mindestens einen Baum oder ein Gebiet auswählen.
                  </div>
                </a>
              </v-col>
              <v-col cols="6">
                <v-btn
                  block
                  size="x-large"
                  height="54px"
                  color="#1db954"
                  class="new-btn"
                  @click="createNewTask"
                >
                  <v-icon>mdi mdi-plus-thick</v-icon>
                  Neue Pflegemassnahme
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row justify="end" v-if="!hideFilters">
          <v-col cols="6">
            <v-btn-toggle
              class="three-buttons"
              active-class="custom-active"
              mandatory
            >
              <v-hover v-slot="{ hover }">
                <v-btn
                  size="x-large"
                  height="54px"
                  outlined
                  class="custom-outlined"
                  :style="{
                    'background-color': hover ? '#1DB954' : 'transparent',
                    color: hover ? 'black' : 'white',
                    'border-color': hover ? 'transparent' : '#494949'
                  }"
                  @click="onTaskFilter(TaskTypes.Open)"
                >
                  Offen
                </v-btn>
              </v-hover>

              <v-hover v-slot="{ hover }">
                <v-btn
                  size="x-large"
                  height="54px"
                  outlined
                  class="custom-outlined"
                  :style="{
                    'background-color': hover ? '#1DB954' : 'transparent',
                    color: hover ? 'black' : 'white',
                    'border-color': hover ? 'transparent' : '#494949'
                  }"
                  @click="onTaskFilter(TaskTypes.Closed)"
                >
                  Erledigt
                </v-btn>
              </v-hover>

              <v-hover v-slot="{ hover }">
                <v-btn
                  size="x-large"
                  height="54px"
                  outlined
                  class="custom-outlined"
                  :style="{
                    'background-color': hover ? '#1DB954' : 'transparent',
                    color: hover ? 'black' : 'white',
                    'border-color': hover ? 'transparent' : '#494949'
                  }"
                  @click="onTaskFilter(TaskTypes.All)"
                >
                  Gesamtplan
                </v-btn>
              </v-hover>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card :loading="isloading">
      <BaumControlListDatatable
        :hideSelection="hideSelection"
        :headers="isMobileViewport ? headersMobile : headers"
        :tableData="tableDataWithYear"
        :showSelect="true"
        :singleSelect="false"
        :actions="[
          { id: 1, icons: require('../../../assets/images/icons/eye-outline.svg'), action: 'view', params: '' },
          {
            id: 2,
            icons: require('../../../assets/images/icons/edit-outline.svg'),
            action: 'createEditBaumK',
            params: ''
          },
          { id: 3, icons: require('../../../assets/images/icons/pencil-outline.svg'), action: 'edit', params: '' }
        ]"
        @createEditBaumK="createEditBKMethod"
        @edit="editTaskMethod"
        @view="viewBKMethod"
        @selectedRow="getSelectedItems"
        :userCanView="userCanView"
        :userCanEdit="userCanEdit"
        :userCanDelete="userCanDelete"
      />
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Tasks from "@/consts/taskTypes";
import BaumControlListDatatable from "../../../components/custom_components/BaumControlListDatatable.vue";
import { eventBus } from "../../../main";
import axios from "axios";
export default {
  components: {
    BaumControlListDatatable
  },
  props: {
    hideFilters: Boolean,
    passedTreeId: Number,
    hideSelection: Boolean
  },
  data() {
    return {
      /* useraccess */
      userCanEdit: false,
      userCanView: false,
      userCanDelete: false,
      /* end of useraccess */

      isMobileViewport: false,
      showInfo: false,
      isloading: true,
      years: [],
      headers: [
      { text: "Bak", value: "colorTypeId" },
        {
          text: "Etappe",
          align: "start",
          value: "date"
        },
        { text: "BaumID", value: "treeName" },
        {
          text: "Massnahme",
          value: "workType",
          sortable: false
        },
        {
          text: "Intervall",
          value: "interval",
          sortable: false
        },
        {
          text: "Spez. Std",
          value: "specialHours",
          sortable: false
        },
        {
          text: "Bp1.Std",
          value: "normalHours",
          sortable: false
        },
        { text: "Mat.Fr.", value: "material", sortable: false },
        { text: "Datum", value: "finishDate" },
        {
          text: "Visum",
          value: "examinationFinishedUsers",
          sortable: false
        },
        {
          text: "Tage bis Versiegelung",
          value: "sealDays"
        },
        { text: "", value: "buttons", align: "start", sortable: false }
      ],
      headersMobile: [
        {
          text: "Etappe",
          align: "start",
          value: "date",
          width: "120px"
        },
        { text: "BaumID", value: "treeName", width: "110px" },
        {
          text: "Massnahme",
          value: "workType",
          width: "140px",
          sortable: false
        },
        {
          text: "Intervall",
          value: "interval",
          width: "70px",
          sortable: false
        },
        {
          text: "Spez. Std",
          value: "specialHours",
          width: "75px",
          sortable: false
        },
        {
          text: "Bp1.Std",
          value: "normalHours",
          width: "70px",
          sortable: false
        },
        { text: "Mat.Fr.", value: "material", width: "70px", sortable: false },
        { text: "Datum", value: "finishDate", width: "120px" },
        {
          text: "Visum",
          value: "examinationFinishedUsers",
          width: "95px",
          sortable: false
        },
        {
          text: "Tage bis Versiegelung",
          value: "sealDays"
        },
        { text: "", value: "buttons", align: "start", sortable: false }
      ],
      tableData: [],
      years: [],
      year: 0,
      taskType: 2,
      TaskTypes: null,
      selectedColor: null,
      colorOptions: [
        { text: 'Keine', value: null },
        { text: 'Rot', value: 'Red' },
        { text: 'Grün', value: 'Green' },
        { text: 'Blau', value: 'Blue' },
        { text: 'Gelb', value: 'Yellow' },
        { text: 'Grau', value: 'Gray' },
      ],
    };
  },
  computed: {
    ...mapGetters(["inventarId", "getTreeItem", "getAreaItem"]),
    tableDataWithYear() {
      const currentYear = new Date().getFullYear();
      return this.tableData.map(row => ({
        ...row,
        year: row.date ? new Date(row.date).getFullYear() : currentYear,
      }));
    },
    orderedYearsWithAll() {
      let reversedYears = this.years.slice().reverse();
      return [{ text: 'Alle Jahre', value: 0 }, ...reversedYears.map(year => ({ text: year.toString(), value: year }))];
    },
    orderedYears() {
      return this.years.slice().reverse();
    },
   
  },
  // created() {
  //   const date = new Date();
  //   this.year = date.getFullYear();

  // },
  mounted() {
    // if(this.inventarSelectedYear){
    //   this.year = this.inventarSelectedYear
    // }
    
    /* check viewportsizes */
    this.updateIsMobile();
    window.addEventListener("resize", this.updateIsMobile);

    this.TaskTypes = Tasks;
    this.fetchYears();
    this.fetchTasks();
  },
  methods: {
    ...mapActions([
      "addTreeItem",
      "updateSelectedYear",
    ]),
    updateIsMobile() {
      const width = window.innerWidth;
      if (width < 1400) {
        this.isMobileViewport = true;
      } else {
        this.isMobileViewport = false;
      }
    },

    async fetchYears() {
      try {
        const response = await axios.get(
          `https://tilia.rrota.org/api/Inventory/GetYearsByInventory/${this.inventarId}`
        );
        this.years = response.data;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
    },

    async fetchTasks() {

      const paramString = this.createParamString();

      eventBus.$emit("resetSelectedTasks");

      try {
        const response = await axios.get(
          `https://tilia.rrota.org/api/TreeExamination/GetAll/${paramString}`
        );

        /* check if there are data */
        if (Object.keys(response.data).length > 0) {
          this.tableData = response.data.result;

          /* change user privileges */
          this.userCanDelete = response.data.access.canDelete;
          this.userCanEdit = response.data.access.canEdit;
          this.userCanView = response.data.access.canView;
        }

        this.isloading = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
    },

    onTaskFilter(tasktype) {
      this.taskType = tasktype;
      this.tableData = [];
      this.fetchTasks();
    },

    yearChange(year) {
      // this.updateSelectedYear(year);
      this.year = year;
      this.tableData = [];
      this.fetchTasks();
    },
    colorChange(color){
      this.selectedColor = color;
      this.tableData = [];
      this.fetchTasks();

    },
    onTaskFilter(tasktype) {
      this.taskType = tasktype;
      this.tableData = [];
      this.fetchTasks();
    },

    createParamString() {
      const params = [];

      if (
        this.taskType !== null &&
        this.taskType !== undefined &&
        this.taskType !== ""
      ) {
        params.push(`TaskType=${this.taskType}`);
      }
    if (
        this.passedTreeId !== null &&
        this.passedTreeId !== undefined &&
        this.passedTreeId !== ""
      ) {
        params.push(`TreeId=${this.passedTreeId}`);
      }
      if(
        this.selectedColor !== null &&
        this.selectedColor !== undefined &&
        this.selectedColor !== ""
      ){
        params.push(`ColorType=${this.selectedColor}`);

      }
      if(this.year == 0){
        params.push(`AllYears=true`)
      }

      return this.inventarId + "/" + this.year + "?" + params.join("&");
    },

    editTaskMethod(item) {
      this.$router.push({
        name: "Pflegemassnahme bearbeiten",
        params: {
          taskId: item.id,
          canDelete: this.userCanDelete,
          year: this.year == 0 ? item.year  : this.year,
          fromBaumKontroll: true
        }
      });
    },

    createEditBKMethod(item) {
      this.$router.push({
        name: "BaumKontrollDetails",
        params: {
          taskId: item.id,
          treeId: item.treeId,
          treeName: item.treeName,
          treeExaminationId: item.treeExaminationId,
          isEditBK: item.isFinished,
          isSealed: item.isSealed,
          treeType: item.treeType,
          viewOnly: false,
          year: this.year == 0 ? item.year : this.year
        }
      });
    },

    viewBKMethod(item) {
    if(!item.finishDate){
      console.log("itemtask",item);
      this.$router.push({
            name: "Pflegeplan Eintrag",
            params: { taskId: item.id, year: this.year == 0 ? item.year : this.year, canEdit: false }
          });
    } else{
      this.$router.push({
        name: "BaumKontrollDetails",
        params: {
          taskId: item.id,
          year: this.year == 0 ? item.year : this.year,
          treeId: item.treeId,
          treeName: item.treeName,
          treeExaminationId: item.treeExaminationId,
          isSealed: item.isSealed,
          isEditBK: item.isFinished,
          treeType: item.treeType,
          viewOnly: true
        }
      });
    }
    },

    createNewTask() {
      this.$router.push({
        name: "Neue Pflegemassnahme",
        params: { fromBaumKontroll: true }
      });
    },
    viewSelectedItemOnMap() {
      if (this.tableData.length > 0 && this.selectedItems) {
        const items = this.tableData.filter(element =>
          this.selectedItems.includes(element)
        );
        const treeItems = items.map(el => el)
        this.addTreeItem(treeItems);
        if (this.getAreaItem && this.getTreeItem) {
          this.$router.push({
            name: "Auswahl"
          });
        }
      } else {
        this.showInfo = !this.showInfo;
            setTimeout(() => {
          this.showInfo = false;
        }, 2000);
      }
    },
    getSelectedItems(items) {
      this.selectedItems = items;
      if (this.selectedItems.length > 0) {

        this.showInfo = false;
      }
    }
  }
};
</script>

<style>
.first-row {
  .karte {
    margin-right: 10px;
    position: relative !important;
  } 
  .information {
    position: absolute;
    padding: 5px !important;
    color: black !important;
    background: #fff !important;
    position: absolute !important;
    top: -31px !important;
    width: 400px !important;
    z-index: 99999;
  }
}
</style>
